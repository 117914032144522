export const AutodeskModulesStrings = {
    AgentAssist: "Price Hub",
    WatchDog: "Watch Dog",
    SalesDashboard: "Sales Dashboard",
    ExpenseDashboard: "Expense Dashboard",
    BlackSwan: "Spotlight",
    Healthcheck: "Healthcheck Dashboard",
    Fringe: "Fringe Dashboard",
    Vault: "Vault Dashboard",
    TEAccrucial: "T&E Accrucial",
    AIPAccrucial: "AIP Accrucial",
    FBPAccrucial: "FBP Accrucial",
    AWSCCForecast: "AWS Cloud Cost Forecast Dashboard",
    TaxTrendAnalysis: "Tax Trend Analysis Forecast Dashboard",
    LeaseRecon: "Lease Recon",
    ESGDashboard: "ESG Dashboard",
    ACVForecastingDashboardTopline: "Merlin Forecasting Topline",
    ACVForecastingDashboardAMER: "Merlin Forecasting AMER",
    ForecastPlatform: "Forecast Platform",
    ApplauseSpendPlatform: "Applause Spend Platform",
    CashFundForecast: "Cash Fund Forecast",
    TaxRecon: "Tax Recon",
    Seesaw: "Seesaw",
    MeetingDigest: "Meeting Digest",
    LumiTopics: "Lumi Topics",
};

export const WatchDogTabs = {
    Search: 0,
    OWSAlert: 1,
};

export const WatchDogTabsStrings = {
    Search: "Search",
    OWSAlert: "OWS Alert",
};
